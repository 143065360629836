import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import TextComponent from '../components/TextComponent';
import MediaComponent from '../components/MediaComponent';

const PollContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  box-sizing: border-box;
`;

const Question = styled.h2`
  color: #333;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
`;

const AnswerButton = styled.div`
  display: block;
  width: 100%;
  padding: 15px;
  margin-bottom: 10px;
  background-color: ${props => props.selected ? "#ffcccb" : "#f5f5f5"};
  border: 2px solid ${props => props.selected ? "#ff0000" : "#ddd"};
  border-radius: 8px;
  font-size: 16px;
  text-align: left;
  box-sizing: border-box;
`;

const ResultMessage = styled.p`
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
  color: ${props => props.isCorrect ? "green" : "red"};
  width: 100%;
`;

const PollResult = ({ assetContent, userChoices }) => {
  return (
    <PollContainer>
      <Question>{assetContent.question}</Question>
      {Object.entries(assetContent.selections).map(([key, value]) => (
        <AnswerButton
          key={key}
          selected={userChoices.includes(value)}
        >
          {value}
        </AnswerButton>
      ))}
      {assetContent.pollType === "quiz" && (
        <ResultMessage isCorrect={userChoices.includes(assetContent.answer)}>
          {userChoices.includes(assetContent.answer) 
            ? "Correct!" 
            : `Incorrect. The correct answer was "${assetContent.answer}"`}
        </ResultMessage>
      )}
    </PollContainer>
  );
};

const AssetReactions = () => {
  const [assetId, setAssetId] = useState('');
  const [feedId, setFeedId] = useState('');
  const [reactions, setReactions] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  
  const fetchReactions = async () => {
    setLoading(true);
    setError('');
    try {
      const params = { assetID: assetId };
      if (feedId) {
        params.feedID = feedId;
      }
      const response = await axios.get(`https://GenZ.feeltiptop.com/api/asset_reaction`, { params });
      const data = response.data;
      if (data.success) {
        setReactions(data.reactions);
      } else {
        setError(data.message);
      }
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to fetch reactions. Please try again.');
    }
    setLoading(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (assetId) {
      fetchReactions();
    } else {
      setError('Please enter an asset ID');
    }
  };

  const renderAssetContent = (assetContent) => {
    if (assetContent.assetType === "poll") {
      const userChoices = reactions.flatMap(reaction => 
        reaction.reaction.reactionDetails.pollChoice 
          ? [reaction.reaction.reactionDetails.pollChoice] 
          : []
      );
      return <PollResult assetContent={assetContent} userChoices={userChoices} />;
    } else if (assetContent.assetType === "text") {
      return <TextComponent assetContent={assetContent} />;
    } else if (assetContent.assetType === "media") {
      return <MediaComponent mediaLink={assetContent.mediaContent} />;
    } else {
      return <p>Unsupported asset type: {assetContent.assetType}</p>;
    }
  };

  const renderReactionDetails = (reaction) => {
    const details = [];
    if (reaction.reaction.reactionDetails.ranking) {
      details.push(<p key="ranking">Ranking: {reaction.reaction.reactionDetails.ranking}</p>);
    }
    if (reaction.reaction.reactionDetails.comment) {
      details.push(<p key="comment">Comment: {reaction.reaction.reactionDetails.comment}</p>);
    }
    if (reaction.reaction.reactionDetails.pollChoice) {
      details.push(<p key="pollChoice">Poll Choice: {reaction.reaction.reactionDetails.pollChoice}</p>);
    }
    return details.length > 0 ? (
      <div key={reaction.reaction.reactionID}>
        <h4 style={{ fontWeight: 'bold', marginTop: '10px' }}>Reaction Details:</h4>
        {details}
      </div>
    ) : null;
  };

  return (
    <div style={{ padding: '20px' }}>
      <form onSubmit={handleSubmit} style={{ marginBottom: '20px' }}>
        <input
          type="text"
          value={assetId}
          onChange={(e) => setAssetId(e.target.value)}
          placeholder="Enter Asset ID"
          style={{ marginRight: '10px', padding: '5px' }}
        />
        <input
          type="text"
          value={feedId}
          onChange={(e) => setFeedId(e.target.value)}
          placeholder="Enter Feed ID (optional)"
          style={{ marginRight: '10px', padding: '5px' }}
        />
        <button type="submit" disabled={loading} style={{ padding: '5px 10px' }}>
          {loading ? 'Loading...' : 'Get Reactions'}
        </button>
      </form>

      {error && (
        <div style={{ color: 'red', marginBottom: '20px' }}>
          <strong>Error:</strong> {error}
        </div>
      )}

      {reactions.length > 0 && (
        <div>
          <h2 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '20px' }}>Asset Reactions</h2>
          <div style={{ border: '1px solid #ccc', padding: '15px', marginBottom: '15px' }}>
            <h3 style={{ fontSize: '18px', fontWeight: 'bold' }}>Asset: {reactions[0].asset.assetName}</h3>
            <p>Brand: {reactions[0].brand.brandName}</p>
            
            {renderAssetContent(reactions[0].asset.assetContent)}
            
            <div style={{ marginTop: '20px' }}>
              <h4 style={{ fontWeight: 'bold' }}>Reactions:</h4>
              {reactions.map((reaction, index) => (
                <div key={reaction.reaction.reactionID} style={{ marginBottom: '15px', borderBottom: index < reactions.length - 1 ? '1px solid #eee' : 'none', paddingBottom: '15px' }}>
                  <p>User: {reaction.user.userName}</p>
                  <p>Reaction Time: {new Date(reaction.reaction.reactionTime).toLocaleString()}</p>
                  <p>Feed: {reaction.feed.name}</p>
                  {renderReactionDetails(reaction)}
                </div>
              ))}
            </div>
            
            <div style={{ marginTop: '20px' }}>
              <h4 style={{ fontWeight: 'bold' }}>Feed Details:</h4>
              <p>Session Link: {reactions[0].feed.sessionLink}</p>
              <p>Session Start: {reactions[0].feed.sessionStartTime}</p>
              <p>Session End: {reactions[0].feed.sessionEndTime}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AssetReactions;