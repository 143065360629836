import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";

const PollContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  box-sizing: border-box;
`;

const Question = styled.h2`
  color: #333;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
`;

const AnswerButton = styled.button`
  display: block;
  width: 100%;
  padding: 15px;
  margin-bottom: 10px;
  background-color: ${props => props.selected ? "#e0e0e0" : "#f5f5f5"};
  border: 2px solid ${props => props.selected ? "#007bff" : "#ddd"};
  border-radius: 8px;
  font-size: 16px;
  text-align: left;
  cursor: pointer;
  transition: all 0.3s ease;
  box-sizing: border-box;

  &:hover {
    background-color: #e0e0e0;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

const SubmitButton = styled.button`
  display: block;
  width: 100%;
  padding: 15px;
  margin-top: 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-sizing: border-box;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const ResultMessage = styled.p`
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
  color: ${props => props.isCorrect ? "green" : "red"};
  width: 100%;
`;

const ErrorMessage = styled.p`
  color: red;
  text-align: center;
  margin-top: 10px;
`;

const PollComponent = ({ assetContent, token, activeAssetId, feedID, setToken }) => {
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [shuffledChoices, setShuffledChoices] = useState([]);
  const [error, setError] = useState(null);
  const [quizResult, setQuizResult] = useState(null);

  const handleAnswerChange = (value) => {
    if (assetContent.pollType === "multi") {
      setSelectedAnswers(prev => 
        prev.includes(value) ? prev.filter(v => v !== value) : [...prev, value]
      );
    } else {
      setSelectedAnswers([value]);
    }
    setError(null);
  };

  const submitSingleResponse = async (choice) => {
    try {
      const pollResponseData = {
        assetID: activeAssetId,
        feedID: feedID,
        pollChoice: choice,
      };

      console.log("Submitting poll response:", pollResponseData);

      const response = await axios.post(
        "https://GenZ.feeltiptop.com/api/poll_response",
        pollResponseData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.access_token) {
        setToken(response.data.access_token);
      }
      
      if (response.data.success) {
        console.log(`Poll response for "${choice}" submitted successfully`);
        return true;
      } else {
        console.error("Server responded with failure:", response.data.message);
        return false;
      }
    } catch (error) {
      console.error("Error submitting poll response:", error);
      return false;
    }
  };

  const handleSubmit = async () => {
    if (selectedAnswers.length > 0) {
      setIsSubmitted(true);
      setError(null);

      if (assetContent.pollType === "multi") {
        const results = await Promise.all(selectedAnswers.map(submitSingleResponse));
        if (results.every(Boolean)) {
          console.log("All multi-select responses submitted successfully");
        } else {
          setError("Some responses may not have been submitted successfully.");
        }
      } else {
        const success = await submitSingleResponse(selectedAnswers[0]);
        if (success) {
          if (assetContent.pollType === "quiz") {
            setQuizResult(selectedAnswers[0] === assetContent.answer);
          }
        } else {
          setError("Failed to submit response. Please try again.");
          setIsSubmitted(false);
        }
      }
    }
  };

  useEffect(() => {
    setSelectedAnswers([]);
    setIsSubmitted(false);
    setError(null);
    setQuizResult(null);
    
    const choices = Object.entries(assetContent.selections);
    const shuffled = [...choices].sort(() => Math.random() - 0.5);
    setShuffledChoices(shuffled);
  }, [assetContent.question]);

  return (
    <PollContainer>
      <Question>{assetContent.question}</Question>
      {shuffledChoices.map(([key, value]) => (
        <AnswerButton
          key={key}
          onClick={() => handleAnswerChange(value)}
          selected={selectedAnswers.includes(value)}
          disabled={isSubmitted}
        >
          {value}
        </AnswerButton>
      ))}
      {!isSubmitted && (
        <SubmitButton onClick={handleSubmit} disabled={selectedAnswers.length === 0}>
          Submit
        </SubmitButton>
      )}
      {isSubmitted && !error && (
        <ResultMessage>
          Thank you for your response!
        </ResultMessage>
      )}
      {isSubmitted && !error && assetContent.pollType === "quiz" && (
        <ResultMessage isCorrect={quizResult}>
          {quizResult ? "Correct!" : `Incorrect. The correct answer was "${assetContent.answer}"`}
        </ResultMessage>
      )}
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </PollContainer>
  );
};

export default PollComponent;