import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {encrypttoHEX} from './encodedecode.js';

function FeedConstruction(props) {
  const getCurrentTimestamp = () => {
    return new Date().toISOString().replace(/[-:]/g, '').split('.')[0];
  };

  const getDefaultStartTime = () => {
    const currentYear = new Date().getFullYear();
    return new Date(currentYear, 0, 1).toISOString().slice(0, 16);
  };

  const getDefaultEndTime = () => {
    const nextYear = new Date().getFullYear() + 1;
    return new Date(nextYear, 0, 1).toISOString().slice(0, 16);
  };

  const [formData, setFormData] = useState({
    Name: `Feed${getCurrentTimestamp()}`,
    SessionLink: '',
    SessionStartTime: getDefaultStartTime(),
    SessionEndTime: getDefaultEndTime(),
    MaxStarted: '10000',
    MaxCompleted: '10000',
  });

  const [assets, setAssets] = useState([]);
  const [assetPositions, setAssetPositions] = useState({});
  const [feedCreationSuccess, setFeedCreationSuccess] = useState(false);
  const [feedBrands, setFeedBrands] = useState([]);
  const [brandReportURLS, setBrandReportURLS] = useState([]);
  const [feedURL, setFeedURL] = useState("");
  const [feedID, setFeedID] = useState("");
  const [user, setUser] = useState("");

  const protocol = window.location.protocol;
  const hostname = window.location.hostname;
  const port = window.location.port;

  // Construct the baseURL using template literals
  const baseURL = `${protocol}//${hostname}${port ? ':' + port : ''}/`;

  useEffect(() => {
    axios
      .get('https://GenZ.feeltiptop.com/api/getAssets', {
        headers: {
          'Authorization': 'Bearer ' + props.token
        }})
      .then((response) => {
        setAssets(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleSelectChange = (e, assetID, brandID, brandName) => {
    const value = parseInt(e.target.value);
    const newAssetPositions = { ...assetPositions };
    const isBrandInArray = feedBrands.some(brand => brand.brandID === brandID);
    if (value !== 0) {
      if (Object.values(newAssetPositions).includes(value)) {
        alert(
          `Duplicate position selected: ${value}. Each asset must have a unique position.`
        );
        return;
      }
      newAssetPositions[assetID] = value;
      if (!isBrandInArray) {
        setFeedBrands([...feedBrands, {brandID:brandID, brandName : brandName}])
      }
    } else {
      delete newAssetPositions[assetID];
      if (isBrandInArray) {
        setFeedBrands(feedBrands.filter((item) => item.brandID !== brandID));
      }
    }
    setAssetPositions(newAssetPositions);
  };

  const handleInputChange = (e, field) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const now = new Date();
    const oneHourLater = new Date(now.getTime() + 60 * 60 * 1000);
    const formatDateTime = (date) =>
      date.toISOString().slice(0, 19).replace('T', ' ');
    const submissionData = {
      ...formData,
      SessionStartTime: formData.SessionStartTime
        ? formatDateTime(new Date(formData.SessionStartTime))
        : formatDateTime(now),
      SessionEndTime: formData.SessionEndTime
        ? formatDateTime(new Date(formData.SessionEndTime))
        : formatDateTime(oneHourLater),
      SessionLink: feedURL
    };
    console.log(submissionData)
    console.log('Token being sent:', props.token);
    axios
      .post('https://GenZ.feeltiptop.com/api/addFeed', submissionData, {
        headers: {
          'Authorization': `Bearer  ${localStorage.getItem('authToken')}`
        }})
      .then((response) => {
        const tfeedId = response.data.FeedID;
        console.log("tfeedID: ", tfeedId);
        setFeedID(tfeedId);
        createObfuscatedReportURL();
        addFeedAssets(tfeedId);
        setBrandReportURLS(feedBrands.map((br) => {
          let reporturl = createObfuscatedReportURL(br.brandID, tfeedId);
          return {id: br.brandID, name: br.brandName, reporturl: reporturl};
        }))
        setFeedURL(baseURL + createObfuscatedFeedURL(tfeedId))
        console.log(feedURL)
        setFeedCreationSuccess(true);
        axios.post('https://GenZ.feeltiptop.com/api/addFeedURL', {
          FeedID: tfeedId,
          SessionLink: baseURL + createObfuscatedFeedURL(tfeedId)
        }, {
          headers: {
            'Authorization': 'Bearer ' + props.token
          }});
      })
      .catch((error) => {
        console.error('Error adding feed:', error);
        setFeedCreationSuccess(false);
      });
  };

  const addFeedUser = (feedId) => {
    axios
      .post('https://GenZ.feeltiptop.com/api/addFeedUser', {
        feedId,
      }, {
        headers: {
          'Authorization': 'Bearer ' + props.token
        }})
      .then((response) => {
        console.log('feed user added:', response.data);
      })
      .catch((error) => {
        console.error('Error adding feed user:', error);
      });
  };

  const addFeedAssets = (feedId) => {
    console.log(assetPositions)
    axios
      .post('https://GenZ.feeltiptop.com/api/addFeedAssets', {
        feedId,
        assets: assetPositions,
      }, {
        headers: {
          'Authorization': 'Bearer ' + props.token
        }})
      .then((response) => {
        console.log('Feed assets added:', response.data);
      })
      .catch((error) => {
        console.error('Error adding feed assets:', error);
      });
  };

  const createObfuscatedReportURL = (brandID, feedID) => {
    const encryptSTR = JSON.stringify({feedID:feedID, brandID:brandID});
    const URL = "Report?p=" + encrypttoHEX(encryptSTR);
    console.log("Report URL: " + URL);
    return URL;
  }

  const createObfuscatedFeedURL = (feedID) => {
    const encryptSTR = JSON.stringify({feedID:feedID});
    const URL = "Login?p=" + encrypttoHEX(encryptSTR);
    // console.log(encryptSTR);
    // console.log("Login URL: " + URL);
    return URL;
  }
  useEffect(() =>{
    const token = localStorage.getItem("authToken");
    console.log("Token being sent:", token);
  
    axios.get("https://GenZ.feeltiptop.com/api/getCurrentUserInfo", {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then(response => {
      console.log("User info response:", response.data);
      setUser(response.data.username)
    })
    .catch(error => {
      console.error("Error fetching user info:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      }
    });
  });
  return (
    <div>
      <div>Hello {user}</div>

      <div>
        {assets.map((asset) => (
          <div key={asset.AssetID} className="asset-item">
            <span className="asset-label">{`${asset.AssetName}: "${asset.BrandName}" "${asset.Caption}"`}</span>
            <select
              name={`assetPosition${asset.AssetID}`}
              className="position-select"
              onChange={(e) => handleSelectChange(e, asset.AssetID, asset.BrandID, asset.BrandName)}
            >
              <option value="0">0 (Not in feed)</option>
              {Array.from({ length: 100 }, (_, i) => (
                <option key={i} value={`${i + 1}`}>
                  {i + 1}
                </option>
              ))}
            </select>
          </div>
        ))}
      </div>
      <form onSubmit={handleSubmit}>
        <label htmlFor="Name">Name:</label>
        <input
          type="text"
          id="Name"
          name="Name"
          value={formData.Name}
          onChange={(e) => handleInputChange(e, 'Name')}
        />
        <br />
        <label htmlFor="SessionStartTime">Session Start Time:</label>
        <input
          type="datetime-local"
          id="SessionStartTime"
          name="SessionStartTime"
          value={formData.SessionStartTime}
          onChange={(e) => handleInputChange(e, 'SessionStartTime')}
        />
        <br />
        <label htmlFor="SessionEndTime">Session End Time:</label>
        <input
          type="datetime-local"
          id="SessionEndTime"
          name="SessionEndTime"
          value={formData.SessionEndTime}
          onChange={(e) => handleInputChange(e, 'SessionEndTime')}
        />
        <br />
        <label htmlFor="MaxStarted">Max Started:</label>
        <input
          type="number"
          id="MaxStarted"
          name="MaxStarted"
          value={formData.MaxStarted}
          onChange={(e) => handleInputChange(e, 'MaxStarted')}
        />
        <br />
        <label htmlFor="MaxCompleted">Max Completed:</label>
        <input
          type="number"
          id="MaxCompleted"
          name="MaxCompleted"
          value={formData.MaxCompleted}
          onChange={(e) => handleInputChange(e, 'MaxCompleted')}
        />
        <br />
        <button type="submit" className="construct-button">
          Construct Feed
        </button>
      </form>
      {feedCreationSuccess && (
        <div>
          <br/>
          <div className="confirmation-message">
            Feed Created Successfully!
          </div>
          <br/>
          <div className="feedurls">
            <h3>Feed Url:</h3>
            <a href={feedURL} target="_blank" rel="noopener noreferrer">{feedURL}</a>
            <br/>
            <br/>
            <h3>Brand Report Urls:</h3>
              {
                <ul>
                {brandReportURLS.map((branddetails) => (
                  <li key={branddetails.id}>
                    {branddetails.name} - <a href={`${baseURL}${branddetails.reporturl}`} target="_blank" rel="noopener noreferrer">
                      {`${baseURL}${branddetails.reporturl}`}
                    </a>
                  </li>
                ))}
              </ul>
              }
            <h3>Feed Data Dump:</h3>
            <a href={`${baseURL}FeedSessionExport?feedid=${feedID}`} target="_blank" rel="noopener noreferrer">
              {`${baseURL}FeedSessionExport?feedid=${feedID}`}
            </a>
            <br/>
            <br/>
            <h3>Ambassador's Data Dump:</h3>
            <a href={`${baseURL}AmbassadorProfiles`} target="_blank" rel="noopener noreferrer">
              {`${baseURL}AmbassadorProfiles`}
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

export default FeedConstruction;