import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import axios from 'axios';

const EmbedComponent = ({ assetContent }) => {
  const [imageData, setImageData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const isWebScreenCapture = (url) => {
    return url.includes('webscreencapture.tiptopbest.com');
  };

  const getWebScreenCaptureUrl = (url) => {
    const encodedUrl = encodeURIComponent(url);
    return `https://GenZ.feeltiptop.com/api/get_image?url=${encodedUrl}`;
  };

  useEffect(() => {
    const fetchImage = async () => {
      if (isWebScreenCapture(assetContent.embedLink)) {
        try {
          setLoading(true);
          setError(null);
          const response = await axios.get(getWebScreenCaptureUrl(assetContent.embedLink), {
            responseType: 'arraybuffer',
            timeout: 30000 // 30 seconds timeout
          });
          const base64 = btoa(
            new Uint8Array(response.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              '',
            ),
          );
          setImageData(`data:${response.headers['content-type']};base64,${base64}`);
          setLoading(false);
        } catch (err) {
          console.error('Error fetching image:', err);
          setError('Failed to load image');
          setLoading(false);
        }
      }
    };

    fetchImage();
  }, [assetContent.embedLink]);

  if (ReactPlayer.canPlay(assetContent.embedLink)) {
    return (
      <ReactPlayer
        url={assetContent.embedLink}
        controls
        width="100%"
        height="auto"
        onError={(e) => console.error('ReactPlayer error:', e)}
      />
    );
  } else {
    if (isWebScreenCapture(assetContent.embedLink)) {
      return (
        <div>
          {loading && <div>Loading...</div>}
          {error && <div>{error}</div>}
          {imageData && (
            <img
              src={imageData}
              alt="Embedded Content"
              style={{ maxWidth: '100%' }}
            />
          )}
        </div>
      );
    } else {
      return (
        <img
          src={assetContent.embedLink}
          alt="Embedded Content"
          style={{ maxWidth: '100%' }}
          onError={(e) => {
            console.error(`Error loading media: ${e.target.src}`);
            e.target.style.display = 'none';
          }}
        />
      );
    }
  }
};

export default EmbedComponent;