import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import backArrow from '../assets/images/arrow_back.svg';
import '../App.css';

function Agreement(props) {
  const [accept, setAccept] = useState(false);
  const [consent, setConsent] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);

  const navigate = useNavigate();
  const { state } = useLocation();
  const { phoneNumber, userName, password, firstName, lastName, gender, dob, ethnicity, selectedState, interests, email, image } = state;

  const handleBackArrow = () => {
    navigate('/payment', { state: { phoneNumber, userName, password, firstName, lastName, gender, dob, ethnicity, selectedState, interests, email, image } });
  };

  const sendUserData = () => {
    const payload = {
      phoneNumber,
      userName,
      password,
      firstName,
      lastName,
      gender,
      dob,
      ethnicity,
      selectedState,
      interests,
      email,
      image,
      termsAndConditions: accept ? 'Accepted' : 'Not Accepted',
      textMessaging: consent ? 'Consented' : 'Not Consented'
    };

    console.log('Sending data:', payload);

    axios.post('https://GenZ.feeltiptop.com/api/addUser', payload, {
      headers: {
        'Authorization': 'Bearer ' + props.token
      }
    })
    .then(response => {
      console.log('Success:', response.data);
      navigate('/thankyou', { state: { text: 'for signing up!' } });
    })
    .catch(error => {
      setButtonClicked(false);
      alert("Unable to sign up - Error " + error);
      console.error('Error:', error);
    });
  };

  const handleSubmit = () => {
    if (accept && consent && !buttonClicked) {
      setButtonClicked(true);
      sendUserData();
    }
  };

  return (
    <div className="signup-container">
      <div className="arrow-button">
          <img src={backArrow} alt="Logo" className="arrow" onClick={handleBackArrow}/>
      </div>
      <div className="termstitle">
        <a className="title-verification3" href="https://mind360.ai/" target="_blank" rel="noopener noreferrer">Please read our <span className="custom-link">Terms and Conditions:</span></a>
      </div>
      <div className="Conditions">
          <label className="Condition">
              <input
                  type='checkbox'
                  className="TermsCheckbox"
                  checked={accept}
                  onChange={() => setAccept(!accept)}
              />
              <span className="custom-checkbox"></span>
              I accept the Terms and Conditions
          </label>
          <label className="Condition">
              <input
                  type='checkbox'
                  className="TermsCheckbox"
                  checked={consent}
                  onChange={() => setConsent(!consent)}
              />
              <span className="custom-checkbox"></span>
              I consent to receiving text messages from GenZ.
          </label>
      </div>
      <button
        className={`button ${accept && consent ? 'red-background' : 'grey-background'}`}
        disabled={!accept || !consent}
        onClick={handleSubmit}
      >
        Submit
      </button>
    </div>
  );
}

export default Agreement;
