import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import backArrow from '../assets/images/arrow_back.svg';
import '../App.css';
import { decryptfromHEX } from './encodedecode.js';
import CryptoJS from 'crypto-js';

function Login(props) {
  const [usernameOrEmail, setUsernameOrEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [buttonClicked, setButtonClicked] = useState(false);
  let feedID = 1;

  document.title = "GenZ Sign In";
  useEffect(() => {
    const checkExistingToken = async () => {
      const token = localStorage.getItem('authToken');
      if (token) {
        try {
          const response = await axios.get('https://GenZ.feeltiptop.com/api/verify-token', {
            headers: { Authorization: `Bearer ${token}` }
          });
          
          if (response.data.valid) {
            props.setToken(token);
            const userDataResponse = await axios.get('https://GenZ.feeltiptop.com/api/getUserData', {
              headers: { Authorization: `Bearer ${token}` }
            });
            const { userID } = userDataResponse.data;

            if (searchParams.has("p")) {
              let encjson = searchParams.get("p");
              console.log(encjson);
              let jsondatastr = decryptfromHEX(encjson);
              let jsondata;
              try {
                jsondata = JSON.parse(jsondatastr);
                feedID = jsondata.feedID; 
                console.log(feedID);
              } catch (error) {
                setError("It seems there's an issue with the feedv link. Please check the formatting and try again.")
              }
            }
            await fetchData(token, userID);
          }
        } catch (error) {
          localStorage.removeItem('authToken');
        }
      }
    };
  
    checkExistingToken();
  }, [navigate, props]);

  const fetchData = async (token, userID) => {
    try {
      console.log(userID, feedID);
      const feedSessionResponse = await axios.post('https://GenZ.feeltiptop.com/api/getFeedSession', {
        userID,
        feedID,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      const { feedID: sessionFeedID, feedStartTime } = feedSessionResponse.data;
  
      const countResponse = await axios.get(`https://GenZ.feeltiptop.com/api/countUserStatus?feedId=${sessionFeedID}`);
      const userStatusResponse = await axios.get(`https://GenZ.feeltiptop.com/api/getUserStatus`, {
        params: {
          feedID: sessionFeedID,
          userID: userID
        },
        headers: { Authorization: `Bearer ${token}` },
      });
      const { MaxStarted, MaxCompleted } = countResponse.data.statusCheck;
      
      if (userStatusResponse.data.UserStatus === "Completed") {
        alert("User has already completed the feed session.");
        return;
      }
      if ((MaxStarted || MaxCompleted) && userStatusResponse.data.UserStatus !== 'Started' ) {
        alert("Feed is already full");
        return;
      }
  
      const changeFeedUserStatusResponse = await axios.post('https://GenZ.feeltiptop.com/api/changeFeedUserStatus', {
        feedID: sessionFeedID,
        userID,
        feedStartTime
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      const { message, feedStartTime: updatedFeedStartTime } = changeFeedUserStatusResponse.data;
  
      navigate('/feed', {
        state: {
          userID,
          feedID: sessionFeedID,
          sessionEndTime: feedSessionResponse.data.sessionEndTime,
          feedStartTime: updatedFeedStartTime
        }
      });
  
    } catch (error) {
      console.error(error);
      if (error.response) {
        const statusCode = error.response.status;
        switch(statusCode) {
          case 400:
            // alert("Bad Request - The server could not understand the request due to invalid syntax.");
            break;

          case 401:
            alert("Unauthorized - Authentication is required and has failed or has not yet been provided.");
            break;
          case 403:
            alert("Forbidden - You do not have the necessary permissions to access this resource.");
            break;
          case 404:
            alert("An error has occurred. Make sure that the link you have is correct.");
            break;
          case 500:
            alert("Internal Server Error - An unexpected condition was encountered on the server.");
            break;
          default:
            console.log(`Error ${statusCode}: Something went wrong, please try again later.`);
        }
      } else if (error.request) {
        alert("No response was received from the server. Please check your network connection.");
      } else {
        alert("An error occurred while setting up the request.");
      }
    }
  };

  const handleLogin = async () => {
    if (!buttonClicked) {
      setButtonClicked(true);

      if (searchParams.has("p")) {
        let encjson = searchParams.get("p");
        console.log(encjson);
        let jsondatastr = decryptfromHEX(encjson);
        let jsondata;
        try {
          jsondata = JSON.parse(jsondatastr);
          feedID = jsondata.feedID; 
          console.log(feedID);
        } catch (error) {
          setError("It seems there's an issue with the feed link. Please check the formatting and try again.")
        }
      }

      try {
        console.log(CryptoJS.SHA256(password).toString());
        const response = await axios.post('https://GenZ.feeltiptop.com/api/login', {
          usernameOrEmail,
          password: CryptoJS.SHA256(password).toString()
        });
        console.log(response.data)
        const { access_token, userID } = response.data;
        if (access_token) {
          localStorage.setItem('authToken', access_token);
          props.setToken(access_token);
          await fetchData(access_token, userID);
        } else {
          setError("Invalid Username/Email or password");
          setButtonClicked(false);
        }
      } catch (error) {
        console.error('Error:', error);
        setError("Invalid Username/Email or password");
        setButtonClicked(false);
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && usernameOrEmail.length > 3 && password.length > 3) {
      handleLogin();
    }
  };

  const handleInputUserEmailChange = (e) => {
    setUsernameOrEmail(e.target.value.trim());
    setButtonClicked(false);
  };

  const handleInputPasswordChange = (e) => {
    setPassword(e.target.value);
    setButtonClicked(false);
  };

  return (
    <div className="signup-container">
      <div className="arrow-button">
        <Link to="/">
          <img src={backArrow} alt="Logo" className="arrow"/>
        </Link>
      </div>
      <h2 className="title-signup">Login</h2>
  
      <input 
        type="text"
        placeholder="Username or Email"
        className="input-field"
        value={usernameOrEmail}
        onChange={handleInputUserEmailChange}
      />
      <input 
        type="password"
        placeholder="Password"
        className="input-field"
        value={password}
        onChange={handleInputPasswordChange}
        onKeyUp={handleKeyPress}
      />
  
      {error && (
        <div className="error-container2">
          <div className="error-container">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 24" fill="none">
              <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#DB4335" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M12 8V12" stroke="#DB4335" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M12 16H12.01" stroke="#DB4335" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <p className='error'>{error}</p>
          </div>
          <div></div>
          <div className="error-container2">
            <p className="smallCentered-text">Not a GenZ User yet?</p>
            <Link to="/signup" className="apply">Sign Up <span className="highlight">here</span></Link>
          </div>
        </div>
      )}
  
      <button
        className={`button ${usernameOrEmail.length === 0 || password.length === 0 || buttonClicked ? 'grey-background' : 'red-background'}`}
        disabled={usernameOrEmail.length === 0 || password.length === 0 || buttonClicked}
        onClick={handleLogin}
      >
        Login
      </button>
    </div>
  );
}

export default Login;