
export default function commentBox(props){
    const commentBoxStyle = {
        borderBottom: '0.5px solid #D9D9D9',
        display: 'flex',
        width: '1245px',
        padding: '15px 30px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '10px'

    }

    const commentHeaderStyle = {
        display: 'flex',
        alignItems: 'center',
        gap: '13px'
    }

    const ambassadorProfilePictureStyle = {
        display: "flex",
        justifyContent: "left",
        alignItems: "center",
        borderRadius: '8px',
        width: "60px",
        height: "60px"
    }

    const profileNameStyle = {
        color: 'var(--black, #000)',
        fontSize: '18px',
        fontWeight: '400',
        lineHeight: 'normal'
    }

    const commentMainTextStyle = {
        color: 'var(--black, #000)',
        fontSize: '18px',
        fontWeight: '400',
        lineHeight: 'normal',
        width: '1269px'
    }

    return(
        <div style={commentBoxStyle}>
            <div style={commentHeaderStyle}>
                <div style={ambassadorProfilePictureStyle}>
                    <img src={"https://GenZ.feeltiptop.com/" + props.profilePicture} style={{maxHeight: '100%', maxWidth: '100%', borderRadius: '5%'}}>
                    </img>
                </div>
                <p style={profileNameStyle}>
                    {props.name}
                </p>
            </div>
            <p style={commentMainTextStyle}>
                {props.text}
            </p>
        </div>
    )
}