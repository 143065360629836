import React, { useState, useEffect } from 'react';
import axios from 'axios';
import logo from '../assets/images/GenZ_Logo.svg';
import '../formStyle.css';

function BrandCreate(props) {
  const [image, setImage] = useState(null);
  const [error, setError] = useState('');
  const [brandname, setBrandName] = useState('');
  const [user, setUser] = useState(null)

  const isValidFileType = (filename) => {
    return /\.(jpg|jpeg|png|gif|tiff)$/i.test(filename);
  };

  useEffect(() =>{
    const token = localStorage.getItem("authToken");
    console.log("Token being sent:", token);
  


    axios.get("https://GenZ.feeltiptop.com/api/getCurrentUserInfo", {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then(response => {
      console.log("User info response:", response.data);
      setUser(response.data.username)
    })
    .catch(error => {
      console.error("Error fetching user info:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      }
    });


  }, [])

  const handleMediaChangeBrand = (e) => {
    const file = e.target.files[0];
    if (file && isValidFileType(file.name)) {
      setError('');
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      setError('Invalid file type. Please select an image file (jpg, jpeg, png, gif, tiff).');
      setImage(null);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const postData = {
      BrandName: brandname,
      brandImage: image,
    };

    axios
      .post('https://GenZ.feeltiptop.com/api/addBrand', postData, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("authToken")
        }
      })
      .then((response) => {
        console.log('Post response:', response.data);
        alert('Brand created successfully');
      })
      .catch((error) => {
        console.error('Error creating post:', error);
        alert('Error creating post: ' + error.message);
      });
  };

  const handleReset = () => {
    setImage(null);
    setError('');
    setBrandName('');
  };

  return (
    <div>

      <header className="AssetForm-header">
        <img src={logo} className="Ambassco-logo" alt="logo" />
        <div className="formName">Brand Generation Form</div>
      </header>
      <div>Hello {user}</div>
      <form action="" method="POST" encType="multipart/form-data">
        <div className="formElements">
          <label htmlFor="brandName">Brand Name:</label>
          <input
            type="text"
            id="brandNameSelect"
            placeholder="Enter Brand"
            value={brandname}
            onChange={(e) => setBrandName(e.target.value)}
          />
          <label htmlFor="brandPP">
            {image ? <img src={image} alt="Media File" className="mediaInput" /> : 'Brand Profile Picture:'}
          </label>
          <input
            type="file"
            id="fileInput"
            accept="image/jpg, image/jpeg, image/png, image/gif, image/tiff"
            onChange={handleMediaChangeBrand}
          />
          {error && <p className="error">{error}</p>}
          <br />
          <input className="reset" type="reset" onClick={handleReset} />
          <input className="submit" type="submit" onClick={handleSubmit} />
        </div>
      </form>
    </div>
  );
}

export default BrandCreate;
