import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import TextComponent from '../components/TextComponent';
import MediaComponent from '../components/MediaComponent';

const PollContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  box-sizing: border-box;
`;

const Question = styled.h2`
  color: #333;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
`;

const AnswerButton = styled.div`
  display: block;
  width: 100%;
  padding: 15px;
  margin-bottom: 10px;
  background-color: ${props => props.selected ? "#ffcccb" : "#f5f5f5"};
  border: 2px solid ${props => props.selected ? "#ff0000" : "#ddd"};
  border-radius: 8px;
  font-size: 16px;
  text-align: left;
  box-sizing: border-box;
`;

const ResultMessage = styled.p`
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
  color: ${props => props.isCorrect ? "green" : "red"};
  width: 100%;
`;

const PollResult = ({ assetContent, userChoices }) => {
  return (
    <PollContainer>
      <Question>{assetContent.question}</Question>
      {Object.entries(assetContent.selections).map(([key, value]) => (
        <AnswerButton
          key={key}
          selected={userChoices.includes(value)}
        >
          {value}
        </AnswerButton>
      ))}
      {assetContent.pollType === "quiz" && (
        <ResultMessage isCorrect={userChoices.includes(assetContent.answer)}>
          {userChoices.includes(assetContent.answer) 
            ? "Correct!" 
            : `Incorrect. The correct answer was "${assetContent.answer}"`}
        </ResultMessage>
      )}
    </PollContainer>
  );
};

const UserReactions = () => {
  const [userId, setUserId] = useState('');
  const [hashedReactions, setHashedReactions] = useState({});
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  
  const fetchReactions = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await axios.get(`https://GenZ.feeltiptop.com/api/user_reaction`, {
        params: { userID: userId }
      });
      const data = response.data;
      if (data.success) {
        createHashedReactions(data.reactions);
      } else {
        setError(data.message);
      }
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to fetch reactions. Please try again.');
    }
    setLoading(false);
  };

  const createHashedReactions = (reactions) => {
    const newReactions = reactions.reduce((acc, current) => {
      const combinedKey = `${current.reaction.assetID}_${current.reaction.reactionTime}`;
      if(acc[combinedKey]) {
        acc[combinedKey].push(current);
      } else {
        acc[combinedKey] = [current];
      }
      return acc;
    }, {});
    setHashedReactions(newReactions);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (userId) {
      fetchReactions();
    } else {
      setError('Please enter a user ID');
    }
  };

  const renderAssetContent = (reactions) => {
    const assetContent = reactions[0].asset.assetContent;
    if (assetContent.assetType === "poll") {
      const userChoices = reactions.flatMap(reaction => 
        reaction.reaction.reactionDetails.pollChoice 
          ? [reaction.reaction.reactionDetails.pollChoice] 
          : []
      );
      return <PollResult assetContent={assetContent} userChoices={userChoices} />;
    } else if (assetContent.assetType === "text") {
      return <TextComponent assetContent={assetContent} />;
    } else if (assetContent.assetType === "media") {
      return <MediaComponent mediaLink={assetContent.mediaContent} />;
    } else {
      return <p>Unsupported asset type: {assetContent.assetType}</p>;
    }
  };

  const renderReactionDetails = (reactions) => {
    return reactions.map(reaction => {
      const details = [];
      if (reaction.reaction.reactionDetails.ranking) {
        details.push(<p key="ranking">Ranking: {reaction.reaction.reactionDetails.ranking}</p>);
      }
      if (reaction.reaction.reactionDetails.comment) {
        details.push(<p key="comment">Comment: {reaction.reaction.reactionDetails.comment}</p>);
      }
      return details.length > 0 ? (
        <div key={reaction.reaction.reactionID}>
          <h4 style={{ fontWeight: 'bold', marginTop: '10px' }}>Reaction Details:</h4>
          {details}
        </div>
      ) : null;
    });
  };

  return (
    <div style={{ padding: '20px' }}>
      <form onSubmit={handleSubmit} style={{ marginBottom: '20px' }}>
        <input
          type="text"
          value={userId}
          onChange={(e) => setUserId(e.target.value)}
          placeholder="Enter User ID"
          style={{ marginRight: '10px', padding: '5px' }}
        />
        <button type="submit" disabled={loading} style={{ padding: '5px 10px' }}>
          {loading ? 'Loading...' : 'Get Reactions'}
        </button>
      </form>

      {error && (
        <div style={{ color: 'red', marginBottom: '20px' }}>
          <strong>Error:</strong> {error}
        </div>
      )}

      {Object.keys(hashedReactions).length > 0 && (
        <div>
          <h2 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '20px' }}>User Reactions</h2>
          {Object.entries(hashedReactions).map(([key, reactions]) => (
            <div key={key} style={{ border: '1px solid #ccc', padding: '15px', marginBottom: '15px' }}>
              <h3 style={{ fontSize: '18px', fontWeight: 'bold' }}>Asset: {reactions[0].asset.assetName}</h3>
              <p>Reaction Time: {new Date(reactions[0].reaction.reactionTime).toLocaleString()}</p>
              <p>Feed: {reactions[0].feed.name}</p>
              <p>Brand: {reactions[0].brand.brandName}</p>
              
              {renderAssetContent(reactions)}
              {renderReactionDetails(reactions)}
              
              <div style={{ marginTop: '10px' }}>
                <h4 style={{ fontWeight: 'bold' }}>Feed Details:</h4>
                <p>Session Link: {reactions[0].feed.sessionLink}</p>
                <p>Session Start: {reactions[0].feed.sessionStartTime}</p>
                <p>Session End: {reactions[0].feed.sessionEndTime}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UserReactions;