import React, { useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import backArrow from '../assets/images/arrow_back.svg';
import logo from '../assets/images/GenZ_Logo.svg';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';
import CryptoJS from 'crypto-js';
import axios from 'axios';

const SignUp = (props) => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [accept, setAccept] = useState(false);
  const [consent, setConsent] = useState(false);
  const [error, setError] = useState('');
  const [buttonClicked, setButtonClicked] = useState(false);
  const [focusedInput, setFocusedInput] = useState(null);
  const [usernameAvailable, setUsernameAvailable] = useState(true);
  const [emailAvailable, setEmailAvailable] = useState(true);
  const [phoneNumberAvailable, setPhoneNumberAvailable] = useState(true);
  const [checkingAvailability, setCheckingAvailability] = useState(false);
  const navigate = useNavigate();

  document.title = "GenZ Sign Up";

  const debounce = (func, delay) => {
    let debounceTimer;
    return function(...args) {
      const context = this;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    }
  };
  

  const checkAvailability = useCallback((field, value, setter) => {
    if (value.length > 0) {
      setCheckingAvailability(true);
      axios.post('https://GenZ.feeltiptop.com/api/checkAvailability', { field, value })
        .then(response => {
          setter(response.data.available);
          setCheckingAvailability(false);
        })
        .catch(error => {
          console.error('Error checking availability:', error);
          setCheckingAvailability(false);
        });
    } else {
      setter(true);
    }
  }, []);

  const checkUsernameAvailability = useCallback(
    debounce((value) => checkAvailability('username', value, setUsernameAvailable), 1500),
    [debounce, checkAvailability]
  );
  const checkEmailAvailability = useCallback(
    debounce((value) => checkAvailability('email', value, setEmailAvailable), 1500),
    [debounce, checkAvailability]
  );
  const checkPhoneNumberAvailability = useCallback(
    debounce((value) => checkAvailability('phoneNumber', value, setPhoneNumberAvailable), 1500),
    [debounce, checkAvailability]
  );
  
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const checkPassword = (pw) => {
    const decimal = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    return pw !== '' && decimal.test(pw);
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value.replace(/\D/g, '').slice(0, 10);
    setPhoneNumber(value);
    checkPhoneNumberAvailability(value);
  };

  const handleUsernameChange = (e) => {
    const value = e.target.value;
    setUsername(value);
    if (value.length >= 3) {
      checkUsernameAvailability(value);
    }
  };

  const handleSignUp = () => {
    if (!checkPassword(password)) {
      setError("Password does not meet the criteria. Please ensure it is at least 8 characters long and contains at least one lowercase letter, one uppercase letter, one number, and one special character.");
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    if (!accept || !consent) {
      setError("Please accept the terms and conditions and consent to receive text messages.");
      return;
    }

    if (!usernameAvailable || !emailAvailable || !phoneNumberAvailable) {
      setError("Please ensure all fields are available.");
      return;
    }

    if (!buttonClicked) {
      setButtonClicked(true);
      setError('');

      const hashedPassword = CryptoJS.SHA256(password).toString();

      axios.post('https://GenZ.feeltiptop.com/api/token', { passCode: '4zPoQ5KAkR' })
        .then(response => {
          props.setToken(response.data.access_token);
          
          const payload = {
            phoneNumber: phoneNumber || null, 
            userName: username,
            password: hashedPassword,
            firstName,
            lastName,
            email,
            termsAndConditions: accept ? 'Accepted' : 'Not Accepted',
            textMessaging: consent ? 'Consented' : 'Not Consented'
          };

          return axios.post('https://GenZ.feeltiptop.com/api/addUser', payload, {
            headers: {
              'Authorization': 'Bearer ' + response.data.access_token
            }
          });
        })
        .then(response => {
          console.log('Success:', response.data);
          navigate('/thankyou', { state: { text: 'for signing up!' } });
        })
        .catch(error => {
          setButtonClicked(false);
          setError("Unable to sign up - Error " + error);
          console.error('Error:', error);
        });
    }
  };

  const isFormValid = username && email && firstName && lastName &&
                    password && confirmPassword && accept && consent &&
                    usernameAvailable && emailAvailable && phoneNumberAvailable &&
                    !checkingAvailability;

  const styles = {
    pageContainer: {
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '20px',
      boxSizing: 'border-box',
      position: 'relative',
    },
    signupContainer: {
      width: '100%',
      maxWidth: '400px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
      boxSizing: 'border-box',
      backgroundColor: 'rgba(255, 255, 255, 0)',
      borderRadius: '10px',
      zIndex: 2,
      position: 'relative',
    },
    logo: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '500px',
      height: 'auto',
      zIndex: 1,
      opacity: 0.6,
    },
    canvas: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 1,
    },
    arrowButton: {
      alignSelf: 'flex-start',
      marginBottom: '20px',
    },
    arrow: {
      width: '30px',
      height: 'auto',
    },
    titleSignup: {
      marginBottom: '30px',
      textAlign: 'center',
      fontFamily: '"Acumin-BdPro", sans-serif',
      fontSize: '30px',
      fontWeight: 900,
    },
    inputWrapper: {
      width: '100%',
      marginBottom: '20px',
    },
    inputField: {
      width: '100%',
      padding: '15px',
      borderRadius: '10px',
      border: 'none',
      backgroundColor: '#f1f1f1',
      fontSize: '16px',
      fontFamily: '"Acumin-RPro", sans-serif',
      boxSizing: 'border-box',
      outline: 'none',
    },
    focusedInput: {
      border: '1px solid #000000',
    },
    passwordInputContainer: {
      position: 'relative',
      width: '100%',
      marginBottom: '15px',
    },
    togglePasswordButton: {
      position: 'absolute',
      right: '10px',
      top: '50%',
      transform: 'translateY(-50%)',
      background: 'none',
      border: 'none',
      cursor: 'pointer',
    },
    conditions: {
      width: '100%',
      marginBottom: '15px',
    },
    condition: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '10px',
      fontSize: '14px',
    },
    checkbox: {
      marginRight: '10px',
    },
    errorContainer: {
      display: 'flex',
      alignItems: 'center',
      color: '#DB4335',
      marginBottom: '15px',
      fontSize: '14px',
    },
    errorIcon: {
      marginRight: '10px',
    },
    button: {
      width: '100%',
      padding: '15px',
      borderRadius: '10px',
      fontSize: '18px',
      textAlign: 'center',
      fontFamily: '"Acumin-BdPro", sans-serif',
      cursor: 'pointer',
      border: 'none',
      transition: 'background-color 0.3s',
    },
    redBackground: {
      backgroundColor: '#ff2a2f',
      color: '#ffffff',
    },
    greyBackground: {
      backgroundColor: '#eaebeb',
      color: '#afacac',
    },
    errorText: {
      position: 'absolute',
      bottom: '-18px', 
      left: '0',
      color: 'red',
      fontSize: '12px',
      width: '100%', 
    },
  };

  const getInputStyle = (isAvailable, isChecking) => ({
    ...styles.inputField,
    ...(focusedInput === 'username' ? styles.focusedInput : {}),
    ...(isAvailable ? {} : { borderColor: 'red' }),
    ...(isChecking ? { borderColor: 'blue' } : {}) // Indicate checking state
  });

  return (
    <div style={styles.pageContainer}>
      <canvas style={styles.canvas}></canvas>
      <img src={logo} alt="GenZ Logo" style={styles.logo} loading="lazy" />
      <div style={styles.signupContainer}>
        <div style={styles.arrowButton}>
          <Link to="/">
            <img src={backArrow} alt="Back" style={styles.arrow} />
          </Link>
        </div>
        <h2 style={styles.titleSignup}>Create your account</h2>
        <div style={styles.inputWrapper}>
          <input
            type="text"
            placeholder="Username"
            style={getInputStyle(usernameAvailable)}
            value={username}
            onChange={(e) => {
              handleUsernameChange(e)
            }}
            onFocus={() => setFocusedInput('username')}
            onBlur={() => setFocusedInput(null)}
          />
          {!usernameAvailable && <p style={styles.errorText}>Username already exists</p>}
        </div>
        <div style={styles.inputWrapper}>
          <input
            type="email"
            placeholder="Email"
            style={getInputStyle(emailAvailable)}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              checkEmailAvailability(e.target.value);
            }}
            onFocus={() => setFocusedInput('email')}
            onBlur={() => setFocusedInput(null)}
          />
          {!emailAvailable && <p style={styles.errorText}>Email already exists</p>}
        </div>
        <div style={styles.inputWrapper}>
          <input
            type="text"
            placeholder="First Name"
            style={styles.inputField}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            onFocus={() => setFocusedInput('firstName')}
            onBlur={() => setFocusedInput(null)}
          />
        </div>
        <div style={styles.inputWrapper}>
          <input
            type="text"
            placeholder="Last Name"
            style={styles.inputField}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            onFocus={() => setFocusedInput('lastName')}
            onBlur={() => setFocusedInput(null)}
          />
        </div>
        <div style={styles.inputWrapper}>
          <input
            type="tel"
            placeholder="Phone Number (Optional)"
            style={getInputStyle(phoneNumberAvailable)}
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            onFocus={() => setFocusedInput('phoneNumber')}
            onBlur={() => setFocusedInput(null)}
            maxLength={10}

          />
          {!phoneNumberAvailable && <p style={styles.errorText}>Phone number already exists</p>}
        </div>
        <div style={styles.passwordInputContainer}>
          <input
            type={showPassword ? 'text' : 'password'}
            placeholder="Password"
            style={{
              ...styles.inputField,
              ...(focusedInput === 'password' ? styles.focusedInput : {})
            }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onFocus={() => setFocusedInput('password')}
            onBlur={() => setFocusedInput(null)}
          />
          <button onClick={() => setShowPassword(!showPassword)} style={styles.togglePasswordButton}>
            {showPassword ? <BsFillEyeFill /> : <BsFillEyeSlashFill />}
          </button>
        </div>
        <div style={styles.passwordInputContainer}>
          <input
            type={showConfirmPassword ? 'text' : 'password'}
            placeholder="Confirm Password"
            style={{
              ...styles.inputField,
              ...(focusedInput === 'confirmPassword' ? styles.focusedInput : {})
            }}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            onFocus={() => setFocusedInput('confirmPassword')}
            onBlur={() => setFocusedInput(null)}
          />
          <button onClick={() => setShowConfirmPassword(!showConfirmPassword)} style={styles.togglePasswordButton}>
            {showConfirmPassword ? <BsFillEyeFill /> : <BsFillEyeSlashFill />}
          </button>
        </div>
        <div style={styles.conditions}>
          <label style={styles.condition}>
            <input
              type='checkbox'
              style={styles.checkbox}
              checked={accept}
              onChange={() => setAccept(!accept)}
            />
            I accept the Terms and Conditions
          </label>
          <label style={styles.condition}>
            <input
              type='checkbox'
              style={styles.checkbox}
              checked={consent}
              onChange={() => setConsent(!consent)}
            />
           I consent to receiving text messages from GenZ.
          </label>
        </div>
        {error && (
          <div style={styles.errorContainer}>
            <svg style={styles.errorIcon} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#DB4335" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M12 8V12" stroke="#DB4335" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M12 16H12.01" stroke="#DB4335" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <p>{error}</p>
          </div>
        )}
        <button
          style={{
            ...styles.button,
            ...(isFormValid && !buttonClicked ? styles.redBackground : styles.greyBackground)
          }}
          disabled={!isFormValid || buttonClicked}
          onClick={handleSignUp}
        >
          Sign Up
        </button>
      </div>
    </div>
  );
}

export default SignUp;