import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import backArrow from '../assets/images/arrow_back.svg';
import '../App.css'
import axios from 'axios';


function AddEmail() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [buttonClicked, setButtonClicked] = useState(false);
  const navigate = useNavigate();

  const { state } = useLocation();
  const {phoneNumber, userName, password, firstName, lastName, gender, dob, ethnicity, selectedState, interests} = state;

  const handleBackArrow = () => {
    navigate('/SelectInterests', {state: {phoneNumber, userName, password, firstName, lastName, gender, dob, ethnicity, selectedState}});
  };

  const isValidEmail = (email) => {
    // Basic email validation using a regular expression
    const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return emailPattern.test(email);
  };

  const handleSubmit = () => {
    if (!buttonClicked) {
        setButtonClicked(true);
        axios.post('https://GenZ.feeltiptop.com/api/checkEmail', {email: email})
        .then((response) => {
          if (!response.data.exists) {
            console.log(interests)
            navigate('/uploadprofilepicture', { state: { phoneNumber, userName, password, firstName, lastName, gender, dob, ethnicity, selectedState, interests, email: email} });
          } else {
            setError("Email is already being used!")
            setButtonClicked(false);
          }
        })
        .catch((error) => {
          console.error(error);
        });
      }

  }

  return (
    <div className="signup-container">
      <div className="arrow-button">
          <img src={backArrow} alt="Logo" className="arrow" onClick={handleBackArrow}/>
      </div>
      <h2 className='title-verification'>What's your email?</h2>

      <input
        type="email"
        className="input-field"
        value={email}
        placeholder="johnsmith@example.com" 
        onChange={(e) => {
          setEmail(e.target.value);
        }}
      />

      {error && (
        <div className="error-container">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#DB4335" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 8V12" stroke="#DB4335" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 16H12.01" stroke="#DB4335" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          <p className='error'>{error}</p>
        </div>
      )}

      <button
        style={{marginTop:'200px'}}
        className={`button ${isValidEmail(email) ? 'red-background' : 'grey-background'}`}
        disabled={!isValidEmail(email)}
        onClick={handleSubmit}
      >
        Next
      </button>
    </div>
  );
}

export default AddEmail;
