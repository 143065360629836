import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from "react-sortable-hoc";
import logo from "../assets/images/GenZ_Logo.svg";
import { encrypttoHEX } from "./encodedecode.js";
import FeedCommentBox from "../components/FeedCommentBox";
import commentImage from "../assets/reaction_images/Comment.svg";
import commentImageClicked from "../assets/reaction_images/CommentClicked.svg";
import PollComponent from "../components/PollComponent";
import EmbedComponent from "../components/EmbedComponent";
import MediaComponent from "../components/MediaComponent";

// Drag Handle
const DragHandle = SortableHandle(() => (
  <span style={{ cursor: "move", marginRight: 8 }}>::</span>
));

// Sortable Item
const SortableItem = SortableElement(({ asset, handleRemoveAsset }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "8px",
      border: "1px solid #ccc",
      marginBottom: "4px",
      background: "#fff",
      cursor: "move",
    }}
  >
    <DragHandle />
    <span>
      {asset.AssetName}: "{asset.Caption}"
    </span>
    <button onClick={() => handleRemoveAsset(asset.AssetID)}>Remove</button>
  </div>
));

// Sortable List
const SortableList = SortableContainer(({ items, handleRemoveAsset }) => {
  return (
    <div>
      {items.map((asset, index) => (
        <SortableItem
          key={`item-${asset.AssetID}`}
          index={index}
          asset={asset}
          handleRemoveAsset={handleRemoveAsset}
        />
      ))}
    </div>
  );
});

const NewFeedConstruction = (props) => {
  const getCurrentTimestamp = () => {
    return new Date().toISOString().replace(/[-:]/g, "").split(".")[0];
  };

  const getDefaultStartTime = () => {
    const currentYear = new Date().getFullYear();
    return new Date(currentYear, 0, 1).toISOString().slice(0, 16);
  };

  const getDefaultEndTime = () => {
    const nextYear = new Date().getFullYear() + 1;
    return new Date(nextYear, 0, 1).toISOString().slice(0, 16);
  };

  const [formData, setFormData] = useState({
    Name: `Feed${getCurrentTimestamp()}`,
    SessionLink: "",
    SessionStartTime: getDefaultStartTime(),
    SessionEndTime: getDefaultEndTime(),
    MaxStarted: "10000",
    MaxCompleted: "10000",
  });

  const [assets, setAssets] = useState([]);
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [filter, setFilter] = useState("all");
  const [brandName, setBrandName] = useState([]);
  const [filterBrandName,setFilterBrandName]=useState("all")
  const [filterAsset,setFilterAsset]=useState([])
  const [inputSearch,setInputSearch]=useState("")
  const [assetSearch,setAssetSearch]=useState("")
  const { state } = useLocation();
  const { feedID } = state || {}; // Get feedID from state
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get("https://GenZ.feeltiptop.com/api/getAssets", {
        headers: {
          Authorization: "Bearer " + props.token,
        },
      })
      .then((response) => {
        setAssets(response.data);
      })
      .catch((error) => {
        console.error(error);
      });

    if (feedID) {
      console.log(feedID);
      axios
        .get(`https://GenZ.feeltiptop.com/api/getPosts`, {
          params: { feedID: feedID },
          headers: {
            Authorization: "Bearer " + props.token,
          },
        })
        .then((response) => {
          setSelectedAssets(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [feedID, props.token]); // Added props.token as a Warning was coming up about it, remove it if it bugs out code.

  useEffect(() => {
    setBrandName((prevBrandName) => {
      const uniqueBrandNames = new Set([...prevBrandName, ...assets.map(asset => asset.BrandName)]);
      return Array.from(uniqueBrandNames);
    });
  }, [assets]); // Can this be combined with the top useEffect?

  useEffect(()=>{
    setFilterAsset(assets.filter((asset)=>((filter==="all" && filterBrandName==="all")|| (filter==="all" && asset.BrandName===filterBrandName) || (asset.AssetType===filter && asset.BrandName===filterBrandName) || (asset.AssetType===filter && filterBrandName==="all"))));

  },[assets,filterBrandName,filter])


  const onSortEnd = ({ oldIndex, newIndex }) => {
    setSelectedAssets((prevSelectedAssets) =>
      arrayMove(prevSelectedAssets, oldIndex, newIndex)
    );
  };

  const handleAddAsset = (asset) => {
    const isAlreadySelected = selectedAssets.some(
      (selectedAsset) => selectedAsset.AssetID === asset.AssetID
    );

    if (isAlreadySelected) {
      alert("This asset is already selected.");
    } else {
      setSelectedAssets((prevSelectedAssets) => [...prevSelectedAssets, asset]);
    }
  };

  const handleRemoveAsset = (assetID) => {
    setSelectedAssets((prevSelectedAssets) =>
      prevSelectedAssets.filter((asset) => asset.AssetID !== assetID)
    );
  };

  const getAssetLabel = (asset) => {
    const assetName = asset?.AssetName || " ";
    const caption = asset?.Caption || " ";
    return `${assetName}: "${caption}"`;
  };

  const handleFinalize = () => {
    navigate("/FinalizeFeed", {
      state: {
        assets: selectedAssets,
      },
    });
  };

  const renderAssetContent = (post) => {
    const assetContent = JSON.parse(post.AssetContent);

    switch (assetContent.assetType) {
      case "poll":
        return <PollComponent assetContent={assetContent} />;
      case "text":
        return <TextComponent assetContent={assetContent} />;
      case "media":
        console.log(assetContent.media_link);
        return <MediaComponent mediaLink={assetContent.mediaContent} />;
      default:
        return <p>Unsupported asset type: {assetContent.assetType}</p>;
    }
  };
  const TextComponent = ({ assetContent }) => (
    <div dangerouslySetInnerHTML={{ __html: assetContent.html }} />
  );

  const handleSearch=(e)=>{
    e.preventDefault();
    if(inputSearch.length<1){
      return;
    }
    const Filter=assets.find(asset => asset.Caption.toLowerCase().includes(inputSearch.toLowerCase()))
    if (Filter && typeof Filter === 'object' && !Array.isArray(Filter)) {
      setFilterAsset([Filter]);
    } else if (Array.isArray(Filter)) {
      setFilterAsset(Filter);
    } else {
      setFilterAsset([]);
    }
    setInputSearch("")
  }

  const handleAssetSearch=(e)=>{
    e.preventDefault();
    if(assetSearch.length<1){
      return;
    }
    const Filter=assets.find(asset => asset.AssetName.toLowerCase().includes(assetSearch.toLowerCase()))
    if (Filter && typeof Filter === 'object' && !Array.isArray(Filter)) {
      setFilterAsset([Filter]);
    } else if (Array.isArray(Filter)) {
      setFilterAsset(Filter);
    } else {
      setFilterAsset([]);
    }
    setAssetSearch("")
  }

  const renderedPosts = selectedAssets.map((asset) => (
    <div key={asset.AssetID}>
      <div style={{ height: "50px" }}></div>
      <div style={styles.fullPost}>
        <div style={styles.fullCompanyHeader}>
          <div style={styles.postHeader}>
            <div style={styles.companyIcon}>
              {asset.BrandProfilePicture ? (
                <img
                  src={`https://GenZ.feeltiptop.com/${asset.BrandProfilePicture}`}
                  alt="Company Icon"
                  style={styles.companyIconImage}
                />
              ) : (
                <div
                  alt="Blank Company Icon"
                  style={{
                    ...styles.companyIconImage,
                    backgroundImage:
                      "url(https://upload.wikimedia.org/wikipedia/commons/a/a7/Blank_image.jpg)",
                  }}
                />
              )}
            </div>
            <p style={styles.companyName}>{asset.BrandName}</p>
          </div>
          <p style={styles.caption}>{asset.Caption}</p>
        </div>
        <div style={styles.contentContainer}>
          <div style={styles.mediaAndLinkContainer}>
            {renderAssetContent(asset)}
          </div>
          {asset.OpenLink && (
            <a
              href={asset.OpenLink}
              target="_blank"
              style={styles.openLink}
              onClick={(e) => {
                e.preventDefault();
                {/*
                clickLink(
                  props.currentUserID,
                  props.currentFeedID,
                  asset.AssetID
                ); 
                
                No function associated with LinkClick so commented out
                */}
                window.open(asset.OpenLink, "_blank");
              }}
            >
              <span style={styles.openLinkText}>Open Link</span>
              <div style={styles.openLinkArrow}>
                {/* <SharelinkIcon /> Might be a removed/deprecated feature + giving errors on npm start */}
              </div>
            </a>
          )}
        </div>
      </div>
      <div style={{ height: "25px" }}></div>
    </div>
  ));
  return (
    <div style={styles.container}>
      <div style={styles.leftContainer}>
        <div style={styles.innerContainer}>
          <div>
            <form action="#" onSubmit={handleSearch} style={styles.searchForm}>
              <input value={inputSearch} type="text" placeholder="search asset by caption" onChange={(e)=>setInputSearch(e.target.value)}/>
              <button type="submit">search</button>
            </form>
          </div>
          <div style={{ marginTop: '10px' }}>
            <form action="#" onSubmit={handleAssetSearch} style={styles.searchForm}>
              <input value={assetSearch} type="text" placeholder="search by asset name" onChange={(e)=>setAssetSearch(e.target.value)}/>
              <button type="submit">search</button>
            </form>
          </div>
          <div>
            <label htmlFor="assetFilter">Filter Assets by Type: </label>
            <select
              id="assetFilter"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            >
              <option value="all">All</option>
              <option value="image">Image</option>
              <option value="video">Video</option>
              <option value="poll">Poll</option>
              <option value="text">Text</option>
              <option value="auto-asset">Auto Asset</option>
              <option value="media">Media</option>
            </select>
          </div>

          <div>
          <label htmlFor="brandFilter">Filter Assets by BrandName: </label>
            <select
              id="assetFilter"
              value={filterBrandName}
              onChange={(e) => setFilterBrandName(e.target.value)}
            >
              <option value="all">Brandname</option>
              {
                brandName?.map((brand) => <option value={brand} key={brand}>{brand}</option>)
              }
            </select>
          </div>
          <div style={styles.assetList}>
            {Array.from(filterAsset).map((asset) => (
              <div key={asset?.AssetID} className="asset-item">
                <span className="asset-label">{getAssetLabel(asset)}</span>
                <span className="asset-brandName">{asset?.BrandName}</span>
                <button onClick={() => handleAddAsset(asset)}>Add</button>
              </div>
            ))}
          </div>
          <div style={styles.borderLine}></div>
          <div style={styles.assetList}>
            <h3>Selected Assets</h3>
            <SortableList
              items={selectedAssets}
              onSortEnd={onSortEnd}
              handleRemoveAsset={handleRemoveAsset}
              useDragHandle
            />
          </div>
          <button onClick={handleFinalize}>Finalize Feed</button>
        </div>
      </div>
      <div style={styles.rightContainer}>{renderedPosts}</div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    height: "100vh",
    border: "1px solid #ccc",
  },
  leftContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    borderRight: "1px solid #ccc",
  },
  rightContainer: {
    flex: 1,
    overflowY: "auto",
    padding: "10px",
  },
  innerContainer: {
    padding: "10px",
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  assetList: {
    flex: 1,
    marginTop: "10px",
    overflowY: "auto",
  },
  borderLine: {
    height: "1px",
    backgroundColor: "#ccc",
    margin: "10px 0",
  },
  fullPost: {
    padding: "10px",
    border: "1px solid #ddd",
    borderRadius: "4px",
    marginBottom: "10px",
  },
  fullCompanyHeader: {
    display: "flex",
    flexDirection: "column",
  },
  postHeader: {
    display: "flex",
    alignItems: "center",
  },
  companyIcon: {
    marginRight: "10px",
  },
  companyIconImage: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
  },
  companyName: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  caption: {
    fontSize: "14px",
    marginTop: "5px",
  },
  contentContainer: {
    marginTop: "10px",
  },
  mediaAndLinkContainer: {
    marginBottom: "10px",
  },
  openLink: {
    textDecoration: "none",
    color: "#007bff",
    display: "flex",
    alignItems: "center",
  },
  openLinkText: {
    marginRight: "5px",
  },
  openLinkArrow: {
    width: "16px",
    height: "16px",
  },
  searchForm:{
    display:"flex",
    flexDirection:"row",
    alignItems:"center",
  }
};

export default NewFeedConstruction;
