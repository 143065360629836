import React, { useState, useEffect, useCallback, useMemo } from "react";
// import { Link } from 'react-router-dom';
import axios from "axios";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import logo from "../assets/images/GenZ_Logo.svg";
import { ReactComponent as CommentIcon } from "../assets/svg/comment-icon.svg";
import { ReactComponent as ShareIcon } from "../assets/svg/share-icon.svg";
import { ReactComponent as SuperlikeIcon } from "../assets/svg/superlike-icon.svg";
import { ReactComponent as SharelinkIcon } from "../assets/svg/sharelink-icon.svg";
import { ReactComponent as MoveItem } from "../assets/svg/move_item.svg";
import { ReactComponent as ShareIconClicked } from "../assets/svg/share-icon-clicked.svg";
import { ReactComponent as SuperlikeIconClicked } from "../assets/svg/superlike-icon-clicked.svg";
import { ReactComponent as CommentIconClicked } from "../assets/svg/comment-icon-clicked.svg";
import logoutIcon from "../assets/svg/logout-icon.svg"
import TextComponent from '../components/TextComponent';
import FeedCommentBox from "../components/FeedCommentBox";
import commentImage from "../assets/reaction_images/Comment.svg";
import shareImage from "../assets/reaction_images/Share.svg";
import superfanImage from "../assets/reaction_images/Superfan.svg";
import commentImageClicked from "../assets/reaction_images/CommentClicked.svg";
import shareImageClicked from "../assets/reaction_images/ShareClicked.svg";
import superfanImageClicked from "../assets/reaction_images/SuperfanClicked.svg";
import PollComponent from "../components/PollComponent";
import EmbedComponent from "../components/EmbedComponent";
import ReactPlayer from "react-player";
import MediaComponent from "../components/MediaComponent";

const styles = {
  body: {
    overflow: "hidden", // Not working, but should be fine (WIP)
  },
  companyIcon: {
    display: "flex",
    width: "32px",
    height: "32px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "100px",
    background: "#FFF",
  },
  companyIconImage: {
    width: "32px",
    height: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    objectFit: "contain",
  },
  postHeader: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  companyName: {
    color: "#000",
    fontFamily: "Acumin-RPro",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    margin: 0,
    alignSelf: "center",
  },
  postHeaderCaption: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "12px",
  },
  caption: {
    width: "100%",
    color: "#1C1B1F",
    fontFamily: "Acumin-RPro",
    fontSize: "17px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "19pt",
    textAlign: "left",
    marginBottom: "0px",
    // paddingBottom: "19px"
  },
  fullCompanyHeader: {
    display: "flex",
    width: "100%",
    // maxHeight: "124px",
    padding: "12px 10px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "10px",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%", // Match width with other components
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    //alignItems: "flex-start",
    position: "relative",
    width: "100%",
  },
  contentImage: {
    width: "100%",
    flexShrink: 0,
    background:
      "url(https://upload.wikimedia.org/wikipedia/commons/a/a7/Blank_image.jpg), lightgray 0px -7.493px / 100% 101.751% no-repeat",
  },
  hateToLove: {
    display: "flex",
    width: "61px",
    height: "416px",
    padding: "10px",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    top: "50%",
    transform: "translateY(-50%)",
    left: "81%",
    position: "absolute",
    borderRadius: "40px",
    background: "#FFF",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    accentColor: "#DB4335",
    color: "#DB4335",
  },
  love: {
    color: "var(--Gray-1, #333)",
    fontFamily: "Acumin-RPro",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  hate: {
    color: "var(--Gray-1, #333)",
    fontFamily: "Acumin-RPro",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  openLink: {
    display: "flex",
    width: "100%",
    height: "39px",
    padding: "5px 18px",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#DB4335",
    textDecoration: "none",
  },
  openLinkText: {
    color: "#FFF",
    fontFamily: "Acumin-RPro",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    display: "flex",
    alignItems: "center",
  },
  openLinkArrow: {
    width: "24px",
    height: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  mediaAndLinkContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    position: "relative",
    width: "95%",
    marginLeft:"auto",
    marginRight:"auto",
  },
  reactionFooter: {
    display: "flex",
    width: "100%",
    padding: "10px 12px",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: "36px",
    gap: "10px",
    background: "#FFF",
  },
  reactionList: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "24px",
  },
  commentContainer: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  commentIcon: {
    width: "24px",
    height: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  commentText: {
    color: "var(--Gray-2, #4F4F4F)",
    fontFamily: "Acumin-RPro",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "1",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: 0,
    padding: 0,
  },
  shareContainer: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  shareIcon: {
    width: "24px",
    height: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  shareText: {
    color: "var(--Gray-2, #4F4F4F)",
    fontFamily: "Acumin-RPro",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "1",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: 0,
    padding: 0,
  },
  superlikeContainer: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  superlikeIcon: {
    width: "24px",
    height: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  superlikeText: {
    color: "var(--Gray-2, #4F4F4F)",
    fontFamily: "Acumin-RPro",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "1",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: 0,
    padding: 0,
  },
  fullPost: {
    display: "inline-flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
  },
  feedContainerStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    maxWidth: "432px",
    margin: "auto",
    marginTop: "20px",
  },
  option: {
    width: "60px",
    height: "60px",
    cursor: "pointer",
    margin: "4px", // Add some space around each option
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },

  radioButton: {
    appearance: "none", // Hide default radio button
    backgroundColor: "#000", // Default background
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    cursor: "pointer",
    outline: "none", // Remove the outline

    // Custom style when radio is checked
    ":checked::before": {
      content: '""',
      display: "block",
      width: "100%",
      height: "100%",
      backgroundColor: "#DB4335", // Color when checked
      borderRadius: "50%",
    },
  },
  userButton: {
    backgroundColor: "transparent",
    cursor: "pointer",
    border: "none",
  },
};

const createPostButtonStyle = {
  backgroundColor: "#3897f0",
  color: "#fff",
  padding: "10px 20px",
  borderRadius: "20px",
  cursor: "pointer",
};

function debounce(func, wait) {
  let timeout;
  return function(...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}

const Feed = (props) => {
  const [searchParams] = useSearchParams();
  const [posts, setPosts] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [showCreatePost, setShowCreatePost] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const [selectedOption, setSelectedOption] = useState("");
  const [ratings, setRatings] = useState({});

  const [activeAssetId, setActiveAssetId] = useState(null);

  const [superlikedAssets, setSuperlikedAssets] = useState({});
  const [sharedAssets, setSharedAssets] = useState({});
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [currentComment, setCurrentComment] = useState("");
  const [isShowCommentModal, setIsShowCommentModal] = useState(false);
  const commentModalStyle = {
    width: "393px",
    height: "339px",
    position: "fixed",
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "16px",
    zIndex: 1000,
    display: isCommentModalOpen ? "block" : "none", // Only display when isCommentModalOpen is true
  };

  const renderRadioButtons = (assetID) => {
    const rating = [
      {
        value: "5",
        text: "😍",
      },
      {
        value: "4",
        text: "🙂",
      },
      {
        value: "3",
        text: "😐",
      },
      {
        value: "2",
        text: "🙁",
      },
      {
        value: "1",
        text: "😖",
      },
    ];

    return rating.map((val) => {
      const isSelected =
        ratings[assetID] === val.value ||
        (val.value === "3" && ratings[assetID] === undefined);
      return (
        <div
          key={val.value}
          style={{
            ...styles.option,
          }}
          onClick={() => handleRatingClick(assetID, val.value)}
        >
          <h2
            style={{
              fontSize: isSelected ? "50px" : "40px",
              opacity: isSelected ? 1 : 0.55,
              textAlign: "center",
              marginBottom: "0px",
            }}
          >
            {val.text}
          </h2>
        </div>
      );
    });
  };

  const handleRatingClick = (assetId, rating) => {
    setRatings({ ...ratings, [assetId]: rating });
    handleRatingChange(currentUserID, currentFeedID, assetId, rating);
  };

  const { state } = useLocation();
  const [timer, setTimer] = useState("0:00");
  const [currentFeedID, setCurrentFeedID] = useState(0);
  const [currentUserID, setCurrentUserID] = useState(0);
  const [sessionEndTime, setSessionEndTime] = useState("");
  const [feedStartTime, setFeedStartTime] = useState("");
  const [isFeedComplete, setIsFeedComplete] = useState(false); // State to track if feed is completed
  // const {currentFeedID, currentUserID, sessionEndTime} = state
  const [commentSubmitted, setCommentSubmitted] = useState({});
  document.title = "GenZ Feed";
  const [feedIdQueue, setFeedIdQueue] = useState([]);

  const handleCommentSubmit = (assetId) => {
    // Update the state to indicate that a comment has been submitted for this assetId
    setCommentSubmitted((prev) => ({ ...prev, [assetId]: true }));
  };

  const Timer = ({ feedStartTime }) => {
    useEffect(() => {
      const updateTimer = () => {
        const fstartTime = new Date(feedStartTime);
        const currentTime = new Date();

        const fstartTimeUtc = Date.UTC(
          fstartTime.getFullYear(),
          fstartTime.getMonth(),
          fstartTime.getDate(),
          fstartTime.getHours(),
          fstartTime.getMinutes(),
          fstartTime.getSeconds()
        );

        const currentTimeUtc = Date.UTC(
          currentTime.getFullYear(),
          currentTime.getMonth(),
          currentTime.getDate(),
          currentTime.getHours(),
          currentTime.getMinutes(),
          currentTime.getSeconds()
        );

        const difference = currentTimeUtc - fstartTimeUtc;
        if (difference >= 0) {
          const minutes = Math.floor(difference / 60000);
          const seconds = Math.floor((difference % 60000) / 1000);
          setTimer(
            (minutes < 10 ? "0" : "") +
              minutes +
              ":" +
              (seconds < 10 ? "0" : "") +
              seconds
          );
        }
      };

      const timerInterval = setInterval(updateTimer, 1000);

      return () => clearInterval(timerInterval);
    }, [feedStartTime]);
    //console.log(timer);
    // return <div>{timer}</div>;
  };

  // Function to fetch posts from the backend
  useEffect(() => {
    const logo = document.querySelector(".logo");
    if (logo) {
      logo.style.visibility = "hidden";
    }

    return () => {
      if (logo) {
        logo.style.visibility = "hidden";
      }
    };
  }, []);

  useEffect(() => {
    // If currentFeedID is not 0 (i.e., it is a valid feed ID), make a GET request to the backend to fetch posts for that feed
    if (currentFeedID !== 0) {
      axios
        .get("https://GenZ.feeltiptop.com/api/getPosts", {
          // Include the feed ID as a query parameter in the request
          params: { feedID: currentFeedID },
          // Include an Authorization header with a bearer token for authentication
          headers: { Authorization: "Bearer " + props.token },
        })
        .then((response) => {
          // If the server response contains an access token, update the token state
          response.data.access_token &&
            props.setToken(response.data.access_token);
          // Append the new posts to the existing posts
          setPosts((prevPosts) => [...prevPosts, ...response.data]);
          console.log("post ",posts)
        })
        .catch((error) => {
          // If there's an error fetching the posts, set the error state
          setError(error);
        });
    }
  }, [currentFeedID]);

  useEffect(() => {
    if (state) {
      console.log("hi")
      console.log(state);
      setCurrentFeedID(state.feedID);
      setCurrentUserID(state.userID);
      setSessionEndTime(state.sessionEndTime);
      setFeedStartTime(state.feedStartTime);
    } else {
      if (
        searchParams.has("feedid") &&
        searchParams.has("userid") &&
        searchParams.has("sessionendtime")
      ) {
        setCurrentFeedID(searchParams.get("feedid"));
        setCurrentUserID(searchParams.get("userid"));
        setSessionEndTime(searchParams.get("sessionendtime"));
        setFeedStartTime(searchParams.get("feedstarttime"));
      } else {
        setError("Incomplete Paramter");
      }
    }
    fetchFeedIDS();
    
   
  }, [state, searchParams]);
  const completeFeed = async () => {
    try {
      const response = await axios.post(
        "https://GenZ.feeltiptop.com/api/completeFeed",
        {
          feedId: currentFeedID,
          userId: currentUserID,
        }
      );
      if (response.data.message) {
        console.log("Feed completed successfully");
        setIsFeedComplete(true);
        navigate("/thankyou", { state: { text: "for completing the feed!" } });
      } else {
        console.error("Server responded with failure");
        alert("Server responded with failure");
      }
    } catch (error) {
      console.error("Error completing feed:", error);
      alert("Error completing feed:" + error);
    }
  };

  const handleRatingChange = async (userId, feedId, assetId, rating) => {
    try {
      const response = await axios.post(
        "https://GenZ.feeltiptop.com/api/rate",
        {
          assetID: assetId,
          userID: userId,
          feedID: feedId,
          ranking: rating,
        },
        {
          headers: { Authorization: "Bearer " + props.token },
        }
      );
      response.data.access_token && props.setToken(response.data.access_token);
      if (response.data.success) {
        console.log("Rating submitted successfully");
        console.log(rating);
      } else {
        console.error("Server responded with failure");
      }
    } catch (error) {
      console.error("Error submitting rating:", error);
    }
  };

  const handleLogOut = () => {
    axios.post("https://GenZ.feeltiptop.com/api/logout", {}, {
      headers: { Authorization: `Bearer ${props.token}` }
    })
      .then((response) => {
        props.removeToken();
        localStorage.removeItem('authToken');
        navigate("/login");
      })
      .catch((error) => {
        console.error("Logout error:", error);
      });
  };

  const clickSuperLike = async (userId, feedId, assetId) => {
    try {
      console.log(userId);
      const response = await axios.post(
        "https://GenZ.feeltiptop.com/api/superlike",
        {
          assetID: assetId,
          userID: userId,
          feedID: feedId,
        },
        {
          headers: { Authorization: "Bearer " + props.token },
        }
      );
      response.data.access_token && props.setToken(response.data.access_token);
      if (response.data.success) {
        console.log("Superlike submitted successfully");
        setSuperlikedAssets((prev) => ({
          ...prev,
          [assetId]: !prev[assetId],
        }));
      } else {
        console.error("Server responded with failure");
      }
    } catch (error) {
      console.error("Error submitting superlike:", error);
    }
  };

  const clickShare = async (userId, feedId, assetId) => {
    try {
      const response = await axios.post(
        "https://GenZ.feeltiptop.com/api/share",
        {
          assetID: assetId,
          userID: userId,
          feedID: feedId,
        },
        {
          headers: { Authorization: "Bearer " + props.token },
        }
      );
      response.data.access_token && props.setToken(response.data.access_token);
      if (response.data.success) {
        console.log("Share submitted successfully");
        setSharedAssets((prev) => ({
          ...prev,
          [assetId]: !prev[assetId],
        }));
      } else {
        console.error("Server responded with failure");
      }
    } catch (error) {
      console.error("Error submitting share:", error);
    }
  };

  const superlikeWrapperStyle = (assetId) => ({
    color: superlikedAssets[assetId] ? "#DB4335" : "initial",
  });

  const shareWrapperStyle = (assetId) => ({
    color: sharedAssets[assetId] ? "#DB4335" : "initial",
  });

  const clickLink = async (userId, feedId, assetId) => {
    try {
      const response = await axios.post(
        "https://GenZ.feeltiptop.com/api/linkclick",
        {
          assetID: assetId,
          userID: userId,
          feedID: feedId,
        },
        {
          headers: { Authorization: "Bearer " + props.token },
        }
      );
      response.data.access_token && props.setToken(response.data.access_token);
      if (response.data.success) {
        console.log("Link click submitted successfully");
      } else {
        console.error("Server responded with failure");
      }
    } catch (error) {
      console.error("Error submitting link click:", error);
    }
  };


  const renderAssetContent = (post) => {
    const assetContent = JSON.parse(post.AssetContent);

    switch (assetContent.assetType) {
      case "poll":
        return <PollComponent 
        assetContent={assetContent}
        userID={currentUserID}
        feedID={currentFeedID}
        activeAssetId={post.AssetID}
        token={props.token}
        setToken={props.setToken} />;
      case "text":
        return <TextComponent assetContent={assetContent} />;
      case "media":
        return <MediaComponent mediaLink={assetContent.mediaContent} />;
      default:
        return <p>Unsupported asset type: {assetContent.assetType}</p>;
    }
  };

 
  const fetchFeedIDS = async () => {
    console.log("fetchFeedIDS function initialized");
  
    try {
      const response = await axios.get("https://GenZ.feeltiptop.com/api/fillPQ", {
        params: {
          userID: currentUserID
        },
        headers: {
          Authorization: "Bearer " + props.token
        }
      });
      
      console.log("Feed Id queue data: ", response.data);
      setFeedIdQueue(response.data.data);
      
    } catch (error) {
      console.error("Error fetching feed IDs:", error);
      setError(error.message);
    }
  };

  const renderedPosts = posts.map((post) => (
    <div key={post.AssetID} style={{width: '100%'}}>
      <div style={{ height: "50px", width: '100%' }}></div>
      {/* <hr style={{ borderTop: '1px solid light gray', width: '100%' }} /> */}

      <div className="fixed-footer">
        <div className="footer-content">
          <div className="footer-logo">
            <img src={logo} alt="Logo" />
          </div>
          <div className="footer-timer">
            <Timer feedStartTime={feedStartTime} />
          </div>
          <div className="footer-logout" onClick={handleLogOut}>
              <img src={logoutIcon}></img>
          </div>
        </div>
      </div>
      <div style={styles.fullPost}>
        <div style={styles.fullCompanyHeader}>
          <div style={styles.postHeader}>
            <div style={styles.companyIcon}>
              {post.BrandProfilePicture ? (
                <img
                  src={`https://GenZ.feeltiptop.com/${post.BrandProfilePicture}`}
                  alt="Company Icon"
                  style={styles.companyIconImage}
                />
              ) : (
                <div
                  alt="Blank Company Icon"
                  style={{
                    ...styles.companyIconImage,
                    backgroundImage:
                      "url(https://upload.wikimedia.org/wikipedia/commons/a/a7/Blank_image.jpg)",
                  }}
                />
              )}
            </div>
            <p style={styles.companyName}>{post.BrandName}</p>
          </div>
          <p style={styles.caption}>{post.Caption}</p>
        </div>
        <div style={styles.contentContainer}>
        <div style={styles.mediaAndLinkContainer}>
            {renderAssetContent(post)}
          </div>
          {post.OpenLink && (
            <a
              href={post.OpenLink}
              target="_blank"
              style={styles.openLink}
              onClick={(e) => {
                e.preventDefault();
                clickLink(currentUserID, currentFeedID, post.AssetID);
                window.open(post.OpenLink, "_blank");
              }}
            >
              <span style={styles.openLinkText}>Open Link</span>
              <div style={styles.openLinkArrow}>
                <SharelinkIcon />
              </div>
            </a>
          )}
        </div>
      </div>
      <div style={{ height: "25px" }}></div>
      <button
        onClick={() => {
          setActiveAssetId(post.AssetID);
          setIsCommentModalOpen(true);
        }}
        style={{
          border: "none",
          background: "none",
          padding: 0,
          width: "100%",
        }}
      >
        {" "}
        <img
          style={{ height: "40px", width: "100%" }}
          src={
            commentSubmitted[post.AssetID] ? commentImageClicked : commentImage
          }
        />{" "}
      </button>
    </div>
  ));

  return error ? (
    <div>{error}</div>
  ) : (
    <>
      <div style={styles.feedContainerStyle}>{renderedPosts}</div>
      <FeedCommentBox
        userID={currentUserID}
        feedID={currentFeedID}
        activeAssetId={activeAssetId}
        setActiveAssetId={setActiveAssetId}
        token={props.token}
        setToken={props.setToken}
        isCommentModalOpen={isCommentModalOpen}
        setIsCommentModalOpen={setIsCommentModalOpen}
        currentComment={currentComment}
        setCurrentComment={setCurrentComment}
        onCommentSubmit={() => handleCommentSubmit(activeAssetId)}
      ></FeedCommentBox>
    </>
  );
};

export default Feed;
