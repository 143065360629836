import React, { useState, useEffect } from 'react';
import Select from 'react-select';

import axios from 'axios';
import { CSVLink, CSVDownload } from "react-csv";


function AmbassadorProfiles(props) {
    // Create States
    const [ambprofiles, setAmbprofiles] = useState([]);
    const[csvfile, setCsvfile] = useState("AmbassadorProfiles-" + new Date().toLocaleString() + ".csv")
    // Default set to public
    const [error, setError] = useState(0);

    useEffect(() => {
        // get all brands
        axios
          .get("https://GenZ.feeltiptop.com/api/getAmbassdorProfiles",
        //   {
        //     headers: {
        //       'Authorization': 'Bearer ' + props.token
        //     }}
            )
          .then((response) => {
            // setBrands(response.data.map(opt =>({label: opt[1], value: opt[0]})));
            // setBrands([{label: "b1", value: 0}]);
            setAmbprofiles(response.data);
            console.log("Ambprofile",response);
          })
          .catch((error) => {
            // Handle the error
            console.error(error);
          });
      }, []);

      // function handleExport() {
      //   const csvExporter = new ExportToCsv();
      //   csvExporter.generateCsv(ambprofiles);
      // }
    
      return (
        <div>
         <CSVLink data={ambprofiles} filename={csvfile} target="_blank"> Download</CSVLink>
        </div>
      );
}

export default AmbassadorProfiles;
